import {
  userEmploymentTypes,
  userLanguageYearTypes,
  userSkillYearTypes,
} from '@constants/user';
import dayjs from 'dayjs';

// ---------------------------------------------------------------
// データ加工・フォーマット系
// * nullの可能性がある配列データをnullセーフで扱うための処理
// ---------------------------------------------------------------
export const filterEmptyString = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data
    .filter((item) => {
      return String(item) != '';
    })
    .map((item) => {
      return item;
    });
};

export const getUploadValues = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => {
    return {
      name: item.fileKey,
      preview: item.url,
    };
  });
};

// 指定のユーザー設定値を取得
export const getUserSettingValue = (data, key) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return null;
  }

  let setting = data.find((item) => item.key == key);
  return setting && typeof setting.value != 'undefined' ? setting.value : null;
};

// カテゴリーデータの親要素をそのまま使用した1次配列
export const getCategories = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => item);
};

// カテゴリーデータの子要素のみを抽出した1次配列
export const getCategoriesByChildItems = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data
    .filter(
      (item) =>
        item.options && Array.isArray(item.options) && item.options.length > 0
    )
    .reduce((prev, current) => {
      const items = current.options.map((item) => item);
      return [...prev, ...items];
    }, []);
};

// スキルカテゴリーデータの親要素をそのまま使用した1次配列
export const getSkillCategories = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => item);
};

// スキルカテゴリーデータの子要素のみを抽出した1次配列
export const getSkillCategoriesByChildItems = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data
    .filter(
      (item) =>
        item.options && Array.isArray(item.options) && item.options.length > 0
    )
    .reduce((prev, current) => {
      const items = current.options.map((item) => item);
      return [...prev, ...items];
    }, []);
};

// Dealカテゴリーデータの親要素をそのまま使用した1次配列
export const getDealCategories = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => item);
};

// Dealカテゴリーデータの子要素のみを抽出した1次配列
export const getDealCategoriesByChildItems = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data
    .filter(
      (item) =>
        item.options && Array.isArray(item.options) && item.options.length > 0
    )
    .reduce((prev, current) => {
      const items = current.options.map((item) => item);
      return [...prev, ...items];
    }, []);
};

// ロケーションデータの親要素をそのまま使用した1次配列
export const getLocations = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => item);
};

// ロケーションデータの子要素のみを抽出した1次配列
export const getLocationsByChildItems = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data
    .filter(
      (item) =>
        item.options && Array.isArray(item.options) && item.options.length > 0
    )
    .reduce((prev, current) => {
      const items = current.options.map((item) => item);
      return [...prev, ...items];
    }, []);
};

// 言語データの親要素をそのまま使用した1次配列
export const getLanguages = (data) => {
  if (
    !data ||
    !data.options ||
    !Array.isArray(data.options) ||
    data.options.length < 1
  ) {
    return [];
  }

  return data.options.map((item) => item);
};

// ユーザー経歴情報配列の作成
export const getUserWorkExperienceCareers = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => {
    const employmentType = userEmploymentTypes.options.find(
      (e) => e.value == item.employmentType
    );

    return {
      affiliation: item.affiliation ? item.affiliation : '',
      employmentType: employmentType ? employmentType : null,
      endDate: item.endDate ? item.endDate : '',
      detail: item.detail ? item.detail : '',
      id: item.id ? item.id : null,
      isActive: !item.endDate ? true : false,
      role: item.role ? item.role : '',
      startDate: item.startDate ? item.startDate : '',
    };
  });
};

// ユーザースキル情報配列の作成
export const getUserWorkExperienceSkills = (data, categories) => {
  if (
    !data ||
    !Array.isArray(data) ||
    data.length < 1 ||
    !categories ||
    !Array.isArray(categories)
  ) {
    return [];
  }

  return data.map((item) => {
    const year = userSkillYearTypes.options.find((e) => e.value == item.year);
    const category = categories.find((e) => e.value == item?.category?.id);
    return {
      category: category ? category : null,
      detail: item.detail ? item.detail : '',
      year: year ? year : null,
    };
  });
};

// ユーザー資格情報配列の作成
export const getUserWorkExperienceCertificates = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return [];
  }

  return data.map((item) => {
    return {
      certifiedOn:
        item.certifiedOn && dayjs(item.certifiedOn).isValid()
          ? item.certifiedOn
          : null,
      name: item.name ? item.name : null,
    };
  });
};

// ユーザー言語情報配列の作成
export const getUserWorkExperienceLanguages = (data, languages) => {
  if (
    !data ||
    !Array.isArray(data) ||
    data.length < 1 ||
    !languages ||
    !Array.isArray(languages)
  ) {
    return [];
  }

  return data.map((item) => {
    const name = languages.find((e) => e.label == item.name);
    const year = userLanguageYearTypes.options.find(
      (e) => e.value == item.year
    );
    return {
      name: name ? name : null,
      year: year ? year : null,
      isNative: item.isNative ? item.isNative : null,
    };
  });
};

// 会社タグデータの親要素をそのまま使用した1次配列
export const getCompanyTags = (data) => {
  if (
    !data ||
    !data.options ||
    !Array.isArray(data.options) ||
    data.options.length < 1
  ) {
    return [];
  }

  return data.options.map((item) => item);
};

export const getRandomThreeDataOnArray = (list) => {
  // 引数検証
  if (!list || !Array.isArray(list) || list.length < 0) {
    return [];
  }

  // 配列データの要素数が3つ以内の場合
  if (list.length <= 3) {
    // 2つ以上の要素が存在し、要素にidキーが存在する場合はソート処理実行
    if (list.length > 2) {
      if (list[0].id && list[1].id) {
        list.sort((a, b) => a.id - b.id);
      }
    }
    return list;
  }

  // ランダムデータ配列を初期化
  const randomThreeData = [];

  // 配列をspriceするため、元配列のコピーを作成
  const copy = list.slice();

  // 配列が空になるか、表示タグ数が3つになるまで処理実行
  while (randomThreeData.length < 3 && copy.length > 0) {
    // 参照配列の要素数を加味してからランダムなindex番号を作成
    const index = Math.floor(Math.random() * copy.length);
    // 作成したindex番号の値を表示タグ配列に追加
    randomThreeData.push(copy[index]);
    copy.splice(index, 1);
  }

  // ランダム取得のために並び順が崩れたカテゴリー情報をIDで昇順ソート
  if (randomThreeData.length > 1) {
    if (randomThreeData[0].id && randomThreeData[1].id) {
      randomThreeData.sort((a, b) => a.id - b.id);
    }
  }

  return randomThreeData;
};

export const getFormatNumberAsString = (value) => {
  // 数字フォーマッター
  // const formatter = new Intl.NumberFormat('ja-JP')
  // フォーマッターだと桁数が大きくなりすぎた場合におかしくなるので、正規表現で対応
  // return String(value).match(/^[0-9]{1,}$/) &&
  //   Number.parseInt(value) == Number.parseInt(value)
  //   ? formatter.format(value)
  //   : value

  if (
    String(value).match(/^[0-9.]{1,}$/) == null ||
    Number.parseInt(value) != Number.parseInt(value)
  ) {
    return value;
  }

  const tmp = String(value).split('.');
  return tmp.length > 1
    ? String(tmp[0]).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '.' + tmp[1]
    : String(tmp[0]).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

// ユーザー言語情報配列の作成
export const getProjectConditionPrices = (data, priceTypes) => {
  if (
    !data ||
    !Array.isArray(data) ||
    data.length < 1 ||
    !priceTypes ||
    !Array.isArray(priceTypes)
  ) {
    return [];
  }

  return data.map((item) => {
    const type = priceTypes.find((e) => e.value == item.type);
    const amountMin = item.amountMin
      ? getFormatNumberAsString(item.amountMin)
      : 0;
    const amountMax = item.amountMax
      ? getFormatNumberAsString(item.amountMax)
      : 0;

    return {
      type,
      amountMax,
      amountMin,
    };
  });
};

// ユーザーの最終経歴取得
export const getUserWorkExperienceLastCareer = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) {
    return null;
  }

  // 元配列のindexを破壊しないように、新しい配列を作成
  const dataCopy = data.slice();

  // 最終候補履歴をソートして、一番上澄みを取得
  dataCopy.sort((a, b) => {
    // 片方だけが現在経歴の場合
    if (!a.endDate && b.endDate) {
      return -1;
    }

    if (a.endDate && !b.endDate) {
      return 1;
    }

    // 両方とも現在経歴の場合は、開始日の降順
    if (!a.endDate && !b.endDate) {
      const values = {
        a: dayjs(a.startDate).isValid() ? dayjs(a.startDate).valueOf() : 0,
        b: dayjs(b.startDate).isValid() ? dayjs(b.startDate).valueOf() : 0,
      };
      if (values.a > values.b) return -1;
      if (values.a < values.b) return 1;
      return 0;
    }

    const values = {
      a: dayjs(a.endDate).isValid() ? dayjs(a.endDate).valueOf() : 0,
      b: dayjs(b.endDate).isValid() ? dayjs(b.endDate).valueOf() : 0,
    };
    if (values.a > values.b) return -1;
    if (values.a < values.b) return 1;

    return 0;
  });

  return dataCopy[0];
};

export const sortUserWorkExperienceLastCareer = (data) => {
  // 引数が配列型ではない場合は処理終了
  if (!data || !Array.isArray(data)) return;

  data.sort((a, b) => {
    // 日付オブジェクト作成: 経歴開始日
    const startDates = {
      a: a.startDate && a.startDate != '' ? dayjs(a.startDate) : null,
      b: b.startDate && b.startDate != '' ? dayjs(b.startDate) : null,
    };

    // 日付オブジェクト作成: 経歴終了日
    const endDates = {
      a: a.endDate && a.endDate != '' ? dayjs(a.endDate) : null,
      b: b.endDate && b.endDate != '' ? dayjs(b.endDate) : null,
    };

    // 第1ソート条件: 経歴開始日の降順
    if (!startDates.a || !startDates.b) return 0;
    if (startDates.b && startDates.b.isBefore(startDates.a)) return -1;
    if (startDates.a && startDates.a.isBefore(startDates.b)) return 1;

    // 第2ソート条件: 経歴開始日の降順
    // 日付指定があるものよりnullを先に並べる
    if (!endDates.a && !endDates.b) return 0;
    if (!endDates.a && endDates.b) return -1;
    if (endDates.a && !endDates.b) return 1;
    if (endDates.b && endDates.b.isBefore(endDates.a)) return -1;
    if (endDates.a && endDates.a.isBefore(endDates.b)) return 1;

    return 0;
  });
};

export const convertNumberToStyleValue = (v, d, u) => {
  const unit =
    u && typeof u == 'string' && u.match(/^(px|%|rem|em)$/) ? u : 'px';

  const defaultValue =
    d && d != 0 ? (typeof d == 'number' ? `${d}${unit}` : d) : `0${unit}`;

  return v && v != 0
    ? typeof v == 'number'
      ? `${v}${unit}`
      : v
    : defaultValue;
};
