import React, { createContext } from 'react';

const initialState = {
  enabledFeatures: {
    featureDeal: false,
    featureGlobalAccess: false,
  },
};

export const FeatureContext = createContext({ initialState });
export const FeatureProvider = ({ children }) => {
  const enabledFeatures = {
    deal:
      parseInt(process.env?.REACT_APP_IS_ENABLE_FEATURE_DEAL) == 1
        ? true
        : false,
    globalAccess:
      parseInt(process.env?.REACT_APP_IS_ENABLE_FEATURE_GLOBAL_ACCESS) == 1
        ? true
        : false,
  };

  return (
    <FeatureContext.Provider value={{ enabledFeatures: enabledFeatures }}>
      {children}
    </FeatureContext.Provider>
  );
};
