import {
  navigationDisplayRoutes,
  navigationDealDisplayRoutes,
  navigationPostDisplayRoutes,
  navigationSettingDisplayRoutes,
  navigationPostTagDisplayRoutes,
} from '@routes';
import React, { createContext, useState } from 'react';
import * as utilities from '@helpers/utilities';

const stub = () => {
  throw new Error('forgot to wrap provider');
};

const initState = {
  initMenu: stub,
  changeActiveMenuFromLocation: stub,
  menuItems: null,
  activatedMenuItemIds: null,
  activatedMenuChildItemIds: null,
};
export const AppMenuContext = createContext(initState);
export const AppMenuConsumer = AppMenuContext.Consumer;

export const AppMenuProvider = ({ children }) => {
  const [menuItems, setMenuItems] = useState(null);
  const [activatedMenuItemIds, setActivatedMenuItemIds] = useState(null);
  const [activatedMenuChildItemIds, setActivatedMenuChildItemIds] =
    useState(null);
  const [isUserSettingMenu, setIsUserSettingMenu] = useState(false);
  const [isUserPostMenu, setIsUserPostMenu] = useState(false);
  const [isDealMenu, setIsDealMenu] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isBillingMenu, setIsBillingMenu] = useState(false);

  // メニューリストの初期化
  const initMenu = () => {
    let menuDisplayRoutes = navigationDisplayRoutes;
    if (window.location.pathname.match(/^\/settings/) != null) {
      menuDisplayRoutes = navigationSettingDisplayRoutes;
    }

    if (window.location.pathname.match(/^\/posts/) != null) {
      menuDisplayRoutes = navigationPostDisplayRoutes;
    }

    if (window.location.pathname.match(/^\/post-tags/) != null) {
      menuDisplayRoutes = navigationPostTagDisplayRoutes;
    }

    if (window.location.pathname.match(/^\/search\/deals/) != null) {
      menuDisplayRoutes = navigationDealDisplayRoutes;
    }

    // 今は、料金表以外は表示しないため、サイドバーを請求ページメニューに切り替えない
    // if (window.location.pathname.match(/^\/billing/) != null) {
    //   menuDisplayRoutes = navigationBillingDisplayRoutes
    // }

    // メニューリスト取得
    const menuItems = assignIdAndParent(menuDisplayRoutes);

    setIsUserSettingMenu(
      window.location.pathname.match(/^\/settings/) != null ? true : false
    );

    setIsUserPostMenu(
      window.location.pathname.match(/^\/posts/) != null ? true : false
    );

    setIsDealMenu(
      window.location.pathname.match(/^\/search\/deals/) != null ? true : false
    );

    // 今は、料金表以外は表示しないため、サイドバーを請求ページメニューに切り替えない
    // setIsBillingMenu(
    //   window.location.pathname.match(/^\/billing/) != null ? true : false
    // )

    // メニューリスト設定
    setMenuItems(menuItems);
  };

  // メニュー
  const changeActiveMenuFromLocation = () => {
    let addMenuItem = navigationDisplayRoutes;
    if (window.location.pathname.match(/^\/settings/) !== null) {
      addMenuItem = navigationSettingDisplayRoutes;
    } else if (window.location.pathname.match(/^\/post-tags/) !== null) {
      addMenuItem = navigationPostTagDisplayRoutes;
    } else if (window.location.pathname.match(/^\/posts/) !== null) {
      addMenuItem = navigationPostDisplayRoutes;
    } else if (window.location.pathname.match(/^\/search\/deals/) != null) {
      addMenuItem = navigationDealDisplayRoutes;
    }

    const menuItems = assignIdAndParent(addMenuItem);

    setIsUserPostMenu(
      window.location.pathname.match(/^\/posts/) != null ? true : false
    );

    setIsUserSettingMenu(
      window.location.pathname.match(/^\/settings/) != null ? true : false
    );

    setIsDealMenu(
      window.location.pathname.match(/^\/search\/deals/) != null ? true : false
    );

    // 今は、料金表以外は表示しないため、サイドバーを請求ページメニューに切り替えない
    // setIsBillingMenu(
    //   window.location.pathname.match(/^\/billing/) != null ? true : false
    // )

    const activatedMenuItemIds = getActivatedMenuItemIds(menuItems);
    const activatedMenuChildItemIds = getActivatedMenuChildItemIds(menuItems);
    setActivatedMenuItemIds(activatedMenuItemIds);
    setActivatedMenuChildItemIds(activatedMenuChildItemIds);
  };

  return (
    <AppMenuContext.Provider
      value={{
        activatedMenuItemIds,
        activatedMenuChildItemIds,
        changeActiveMenuFromLocation,
        initMenu,
        menuItems,
        isBillingMenu,
        isDealMenu,
        isUserSettingMenu,
        isUserPostMenu,
      }}
    >
      {children}
    </AppMenuContext.Provider>
  );
};

let uuid = 1;

const assignIdAndParent = (menuItems, parentId) => {
  menuItems = menuItems || [];
  menuItems.forEach((item) => {
    const id = item.id || uuid;
    uuid += 1;
    item.id = id;
    item.parentId = item.parentId || parentId;
    item.active = false;

    if (typeof item.children !== 'undefined') {
      assignIdAndParent(item.children, id);
    }
  });

  return menuItems;
};

const getActivatedMenuItemIds = (menuItems) => {
  let matchingItems = [];
  for (let menuItem of menuItems) {
    // グローバルメニュー（基本）に含まれるメニューの場合
    if (menuItem?.isDashboardRoutes || menuItem?.isFeatureAppRoutes) {
      // 現在のパスから親メニューパスを取得
      const parentPath = utilities.getParentMenuItemPath(
        window.location.pathname
      );
      // 親メニューパスと処理中のメニューパスが一致する場合は、マッチアイテム配列にIDを追加
      if (parentPath == menuItem?.path) matchingItems.push(menuItem.id);
      continue;
    }

    if (window.location.pathname.indexOf(menuItem.path) === 0) {
      if (!menuItem?.isPostRoutes) {
        matchingItems.push(menuItem.id);
      }
    }

    if (menuItem?.isPostRoutes) {
      const params = new URLSearchParams(window.location.search);
      if (
        !params.get('companyId') &&
        window.location.pathname.indexOf(menuItem.path) === 0
      ) {
        matchingItems.push(menuItem.id);
      } else {
        if (Number(params.get('companyId')) === Number(menuItem.companyId)) {
          matchingItems.push(menuItem.id);
        }
      }
    }

    if (typeof menuItem.children !== 'undefined') {
      const childMatchingItems = getActivatedMenuItemIds(menuItem.children);
      matchingItems = [...matchingItems, ...childMatchingItems];
      if (childMatchingItems.length > 0) matchingItems.push(menuItem.id);
    }
  }

  return Array.from(new Set(matchingItems));
};

const getActivatedMenuChildItemIds = (menuItems, isChildMenuItems) => {
  let matchingItems = [];

  for (let menuItem of menuItems) {
    if (
      isChildMenuItems &&
      window.location.pathname.indexOf(menuItem.path) === 0
    ) {
      matchingItems.push(menuItem.id);
    }

    if (typeof menuItem.children === 'undefined') continue;
    const childMatchingItems = getActivatedMenuChildItemIds(
      menuItem.children,
      true
    );

    matchingItems = [...matchingItems, ...childMatchingItems];
  }

  return Array.from(new Set(matchingItems));
};
