import {
  roles,
  employeeCountTypes,
  memberStatuses,
  memberTypes,
} from '@constants/company';
import { statuses as paymentStatuses } from '@constants/payment';
import {
  interviewTypes,
  participationTypes,
  // priceEvaluateTypes,
  priceTypes,
  priceUnitTypes,
  projectOwnerTypes,
  projectTypes,
  publishScopeTypes,
  publishScopeRangeTypes,
  statuses,
  recruitStatuses,
  workAverageTypes,
  workTypes,
  contractTypes,
  relateTypes,
  referenceTypes,
} from '@constants/project';
import {
  genderTypes,
  loginedStatusTypes,
  userConnectionStatusTypes,
  userConnectionTypes,
  userEmploymentTypes,
  userLanguageYearTypes,
  userWorkStatusTypes,
  userSkillYearTypes,
  userParticipationTypes,
} from '@constants/user';
import { dealStatuses } from '@constants/deal';
import { notificationTypes } from '@constants/other';
import dayjs from 'dayjs';

export const getSelectOptions = (list) => {
  return list.map((item) => {
    return {
      label: item.name || item.title,
      value: item.id,
    };
  });
};

export const getAllProjectLabels = () => {
  return {
    participationType: participationTypes.label,
    price: '報酬/給料',
    priceType: priceTypes.label,
    projectType: projectTypes.label,
    projectOwner: 'プロジェクトオーナー',
    workType: workTypes.label,
  };
};

export const getAllUserLabels = () => {
  return {
    genderType: genderTypes.label,
    userWorkStatusTypes: userWorkStatusTypes.label,
  };
};

export const getAllCompanyLabels = () => {
  return {
    employeeCountType: employeeCountTypes.label,
    role: roles.label,
  };
};

export const getParticipationType = (participationType) => {
  const data = participationTypes.options.find(
    (v) => v.value === participationType
  );
  return data ? data.label : '-';
};

export const getPriceType = (priceType) => {
  const data = priceTypes.options.find((v) => v.value === priceType);
  return data ? data.label : '-';
};

export const getPriceUnitType = (priceType) => {
  const data = priceUnitTypes.options.find((v) => v.value === priceType);
  return data ? data.label : '-';
};

export const getProjectType = (projectType) => {
  const data = projectTypes.options.find((v) => v.value === projectType);
  return data ? data.label : '-';
};

export const getProjectOwnerType = (projectOwnerType) => {
  const data = projectOwnerTypes.options.find(
    (v) => v.value === projectOwnerType
  );
  return data ? data.label : '-';
};

export const getWorkType = (workType) => {
  const data = workTypes.options.find((v) => v.value === workType);
  return data ? data.label : '-';
};

export const getInterviewType = (interviewType) => {
  const data = interviewTypes.options.find((v) => v.value === interviewType);
  return data ? data.label : '-';
};

export const getWorkAverageType = (workAverageType) => {
  const data = workAverageTypes.options.find(
    (v) => v.value === workAverageType
  );
  return data ? data.label : '-';
};

export const getProjectStatus = (status) => {
  const data = statuses.options.find((v) => v.value === status);
  return data ? data.label : '-';
};

export const getProjectRecruitStatus = (status) => {
  const data = recruitStatuses.options.find((v) => v.value === status);
  return data ? data.label : '-';
};

export const getProjectRelateType = (relateType) => {
  const data = relateTypes.options.find((v) => v.value === relateType);
  return data ? data.label : '-';
};

export const getProjectReferenceType = (referenceType) => {
  const data = referenceTypes.options.find((v) => v.value === referenceType);
  return data ? data.label : '-';
};
export const getProjectPublishScopeType = (publishScopeType) => {
  const data = publishScopeTypes.options.find(
    (v) => v.value === publishScopeType
  );
  return data ? data.label : '-';
};
export const getProjectPublishScopeRangeType = (publishScopeRangeType) => {
  const data = publishScopeRangeTypes.options.find(
    (v) => v.value === publishScopeRangeType
  );
  return data ? data.label : '-';
};

/**
 * ユーザー系
 */
export const getGenderType = (genderType) => {
  const data = genderTypes.options.find((v) => v.value === genderType);
  return data ? data.label : '-';
};

export const userWorkStatusType = (userWorkStatusType) => {
  const data = userWorkStatusTypes.options.find(
    (v) => v.value === userWorkStatusType
  );
  return data ? data.label : '-';
};

export const loginedStatusType = (loginedStatusType) => {
  const data = loginedStatusTypes.options.find(
    (v) => v.value === loginedStatusType
  );
  return data ? data.label : '-';
};

export const userSkillYearType = (userSkillYearType) => {
  const data = userSkillYearTypes.options.find(
    (v) => v.value === userSkillYearType
  );
  return data ? data.label : '-';
};

export const userLanguageYearType = (userLanguageYearType) => {
  const data = userLanguageYearTypes.options.find(
    (v) => v.value === userLanguageYearType
  );
  return data ? data.label : '-';
};

export const userEmploymentType = (userEmploymentType) => {
  const data = userEmploymentTypes.options.find(
    (v) => v.value === userEmploymentType
  );
  return data ? data.label : '-';
};

export const getUserConnectionStatusType = (userConnectionStatusType) => {
  const data = userConnectionStatusTypes.options.find(
    (v) => v.value === userConnectionStatusType
  );
  return data ? data.label : '-';
};

export const getUserConnectionType = (userConnectionType) => {
  const data = userConnectionTypes.options.find(
    (v) => v.value === userConnectionType
  );
  return data ? data.label : '-';
};

export const getUserParticipationType = (userParticipationType) => {
  const data = userParticipationTypes.options.find(
    (v) => v.value === userParticipationType
  );
  return data ? data.label : '-';
};

/**
 * 会社系
 */
export const getCompanyRole = (role) => {
  const data = roles.options.find((v) => v.value === role);
  return data ? data.label : '-';
};

export const getEmployeeCount = (employeeCountMin, employeeCountMax) => {
  const data = employeeCountTypes.options.find((v) => {
    return v.min === employeeCountMin && v.max === employeeCountMax;
  });
  return data ? data.label : '-';
};
export const getCompanyMemberStatus = (status) => {
  const data = memberStatuses.options.find((v) => v.value === status);
  return data ? data.label : '-';
};
export const getCompanyMemberTypes = (type) => {
  const data = memberTypes.options.find((v) => v.value === type);
  return data ? data.label : '-';
};
export const getCompanyMemberInvitationTypes = (invitationType) => {
  const data = memberTypes.options.find((v) => v.value === invitationType);
  return data ? data.label : '-';
};

/**
 * ユーザー系
 */
export const getUserStatus = (status) => {
  const data = statuses.options.find((v) => v.value === status);
  return data ? data.label : '-';
};

/**
 * 支払い系
 */
export const getPaymentStatus = (status) => {
  const data = paymentStatuses.options.find((v) => v.value === status);
  return data ? data.label : '-';
};

/**
 * デフォルト取得系
 */
export const getDefaultProjectStatus = () => {
  return 'DRAFT';
};

export const getDefaultProjectExpiredOn = () => {
  return dayjs().add(14, 'days').format('YYYY-MM-DD');
};

export const getDefaultProjectContractType = () => {
  return contractTypes.options[0];
};

/**
 * Deal系
 */
export const getDealStatus = (status) => {
  const data = dealStatuses.options.find((v) => v.value === status);
  return data ? data.label : '-';
};

/**
 * その他系
 */
export const notificationType = (notificationType) => {
  const data = notificationTypes.options.find(
    (v) => v.value === notificationType
  );
  return data ? data.label : '-';
};
